import React from "react"
import Rafiki from "../../assets/images/vectors/rafiki.svg"
import { demoForm } from "../../constants"

export default function landing() {
  return (
    <React.Fragment>
      <div id="landing" className="landing">
        <div className="buffer only-short-height"></div>

        <div className="landing__container">
          <div className="landing__left">
            <p
              className="main-heading main-heading--primary"
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              Resolve complaints and disputes outside court
            </p>
            <div className="u-margin-top-medium"></div>
            <div className="landing__content">
              <p>
                Webnyay is a sophisticated online dispute resolution ecosystem.
              </p>
              <div className="u-margin-top-medium"></div>
              <p>
                We provide an end to end digital platform for resolution of
                complaints and disputes in an efficient, speedy, flexible and
                inexpensive manner.
              </p>
            </div>
            <div className="u-margin-top-medium"></div>
            <div className="landing__button-container">
              <a target="__blank" href={demoForm}>
                <button
                  className="btn btn--transparent btn--largess btn--rounded"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-duration="700"
                >
                  Request a demo
                </button>
              </a>
              <div className="buffer"></div>
              <a href="https://app.webnyay.in" target="__blank">
                <button
                  className="btn btn--contained  btn--largess btn--rounded "
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-duration="700"
                >
                  sign up
                </button>
              </a>
            </div>
          </div>
          <div className="landing__grow"></div>
          <div className="landing__right">
            <img
              src={Rafiki}
              alt="landing page main image"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
