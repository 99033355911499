import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <div className="professional" id="professional">
      <div className="professional__container">
        <p
          className="section-text section-text--professional"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Are you an Arbitrator, Mediator or Conciliator? Do you want to be part
          of the Online Dispute Resolution (ODR) revolution?
        </p>
        <div className="u-margin-top-medium"></div>
        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=qjfY1zkcekWz6XUWzxZ2X7QtQAXgGixEsmvvze2zb0ZUMVVIVlRCVUtSVFc2MTFUQkVKUzhCUDY0RC4u"
          target="__blank"
        >
          <button
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
            className="btn btn--contained  btn--large btn--rounded"
          >
            register here
          </button>
        </a>
      </div>
    </div>
  )
}
