import { Link } from "gatsby"
import React from "react"
import CiiIcon from "../../assets/images/icons/cii.svg"
import ClaIcon from "../../assets/images/icons/cla.svg"
import VidhyaIcon from "../../assets/images/icons/vid.svg"
import IlaIcon from "../../assets/images/icons/ila.svg"

const data1 = [
  {
    icon: CiiIcon,
    title: "Thought Leadership Partner",
    to: "https://www.cii.in/",
  },
  {
    icon: ClaIcon,
    title: "Knowledge Devlopment Partner",
    to: "https://www.claonline.in/",
  }
]

const data2 = [
  {
    icon: VidhyaIcon,
    title: "Knowledge Sharing Partner",
    to: "https://www.vidhionline.com/",
  },
  {
    icon: IlaIcon,
    title: "Ecosystem Partner",
    to: "https://www.indialawyers.org/",
  }
]
export default function quickpartners() {
  return (
    <div id="partners" className="partners">
      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        <h2
          className="main-heading text-blue main-heading--capital"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Our partners
        </h2>
        
      </div>
      <div
        className="partners__content1 "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        
        <div className="partners__container">
        
          
          {data1.map(singleEntry => {
            return (
              <div className="partners__icon-container" key={singleEntry.title}>
                <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                <div className="buffer"></div>
                <h5>{singleEntry.title}</h5>
              </div>
            )
          })}
        </div>
        <br />
        <div className="partners__container">
          {data2.map(singleEntry => {
            return (
              <div className="partners__icon-container" key={singleEntry.title}>
                <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                <div className="buffer"></div>
                <h5>{singleEntry.title}</h5>
              </div>
            )
          })}
        </div>
      </div>
    </div>
    
    
  )
}
