import React from "react"
import Landing from "../components/homeSections/landing"
import Partners from "../components/homeSections/partnersLanding"
import Products from "../components/homeSections/products"
import Professional from "../components/homeSections/professional"
import QuickPoints from "../components/homeSections/quickPoints"
import Layout from "../components/layout"
import Product from "../components/workingSections/workingProduct"

const IndexPage = () => (
  <Layout>
    <Landing />
    {/* <About /> */}
    <QuickPoints />
    <Products />
    <Partners />
    <Professional />
    <Product />
  </Layout>
)

export default IndexPage
