import { Link } from "gatsby"
import React from "react"
import IconOne from "../../assets/images/icons/icon1.svg"
import IconTwo from "../../assets/images/icons/icon2.svg"
import IconThree from "../../assets/images/icons/icon3.svg"
import IconFour from "../../assets/images/icons/icon4.svg"
import LinkBlue from "../../assets/images/icons/linkArrowBlue.svg"
import LinkOrange from "../../assets/images/icons/linkArrowOrange.svg"
const data = [
  { icon: IconOne, title: "Efficient & speedy" },
  { icon: IconTwo, title: "No Paperwork" },
  { icon: IconThree, title: "5 days maximum" },
  { icon: IconFour, title: "Legally Enforceable" },
]

export default function quickproducts() {
  return (
    <div id="products" className="products">
      <div
        className="products__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        <h2
          className="main-heading text-blue main-heading--capital"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Our products
        </h2>
        <div className="u-margin-top-medium"></div>
        <p>
          Webnyay provides everything that is required to resolve disputes in
          one place without having to go anywhere. Our success is attributed to
          our rigorous protocols, security, uncompromised quality and our
          commitment to delivering an excellent service. We have the following
          service offerings:
        </p>
        <div className="u-margin-top-large"></div>
        <h2 className="products__secondary-heading">
          CDR - Commercial Dispute Resolution
        </h2>
        <div className="u-margin-top-medium"></div>
        <p>
          This is an innovative and technologically advanced service that seeks
          to resolve B2B, B2C and C2C complaints, conflicts and disputes. It is
          a one-stop shop for enterprises looking to resolve civil and
          commercial disputes (including consumer complaints) and grievances
          from employees, suppliers and distributors. Our sophisticated document
          automation technology allows users to generate legal pleadings by
          simply answering questions and uploading documents (and without having
          to engage lawyers).
        </p>
        <div className="u-margin-top-medium"></div>
        <p>
          We work with experienced professionals (which includes former judges,
          ombudsmen, senior advocates, queen's counsel, lawyers and domain
          experts) that act as neutrals on the disputes. We use Artificial
          Intelligence in our legal and automation processes to ensure that
          disputes are resolved efficiently and inexpensively.
        </p>
        <div className="u-margin-top-medium"></div>
        <div className="flex">
          <Link to="/cdr">
            <LinkOrangeComp text="Commercial Dispute Resolution" />
          </Link>
          <div className="u-width-medium"></div>
          <Link
            to="https://www.youtube.com/watch?v=ysiTkYrSurQ&ab_channel=WebnyayPvtLtd"
            target="__blank"
          >
            <LinkBlueComp text="Watch Video" />
          </Link>
        </div>
        <div className="u-margin-top-large"></div>
        <h2 className="products__secondary-heading">RV - Resolve Virtually</h2>
        <div className="u-margin-top-medium"></div>
        <p>
          This is a state-of-the-art platform that can be used to resolve
          conflicts and disputes online. It is a plug-and-play technology system
          that can be used by anybody to resolve conflicts and disputes in a
          secure and seamless manner. In layman terms, this platform "digitises"
          dispute resolution (ie, offline to online) and enables all
          stakeholders to resolve differences online. It is ideal for conducting
          court hearings, arbitrations, mediations, conciliations, Lok adalats,
          adjudications, grievance and ombudsman redressals, etc.
        </p>
        <div className="u-margin-top-medium"></div>
        <div className="flex">
          <Link to="/rv">
            <LinkOrangeComp text="Resolve virtually" />
          </Link>
          <div className="u-width-medium"></div>

          <Link
            to="https://www.youtube.com/watch?v=-l_W5B43IKc&feature=youtu.be&ab_channel=WebnyayPvtLtd"
            target="__blank"
          >
            <LinkBlueComp text="Watch Video" />
          </Link>
        </div>

        <div className="u-margin-top-large"></div>
        <h2 className="products__secondary-heading">Institutional Offering</h2>
        <div className="u-margin-top-medium"></div>
        <p>
          When selected by the parties, Webnyay acts as a neutral and
          independent arbitration institution that provides secretarial
          services. We see ourselves as a new-age arbitration institute catering
          to domestic and cross-border arbitration disputes. We are an
          online-first and technology-first institution that not only provides
          world class case management and administrative services but also an
          online platform for managing and conducting the arbitration
          proceedings.{" "}
        </p>
        <div className="u-margin-top-medium"></div>
        <div className="flex">
          <Link to="/institutional-offering">
            <LinkOrangeComp text="Read More" />
          </Link>
        </div>
      </div>
    </div>
  )
}

const LinkOrangeComp = ({ text }) => {
  return (
    <div className="flex">
      <span className="products__link text-orange">{text}</span>
      <div className="u-width-xsmall"></div>
      <img src={LinkOrange} alt="" />
    </div>
  )
}

const LinkBlueComp = ({ text }) => {
  return (
    <div className="flex">
      <span className="products__link text-blue">{text}</span>
      <div className="u-width-xsmall"></div>
      <img src={LinkBlue} alt="" />
    </div>
  )
}
