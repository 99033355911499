import React from "react"
import IconOne from "../../assets/images/icons/icon1.svg"
import IconTwo from "../../assets/images/icons/icon2.svg"
import IconThree from "../../assets/images/icons/icon3.svg"
import IconFour from "../../assets/images/icons/icon4.svg"

const data = [
  { icon: IconOne, title: "Efficient & speedy" },
  { icon: IconTwo, title: "No Paperwork" },
  { icon: IconThree, title: "Confidential & Secure" },
  { icon: IconFour, title: "Legally Enforceable" },
]

export default function quickPoints() {
  return (
    <div id="points" className="points">
      <div className="points__content">
        <h2
          className="main-heading text-blue main-heading--capital"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Our vision
        </h2>
        <div className="u-margin-top-medium"></div>
        <p>
          Our vision is to provide access to justice for everyone. We seek to
          empower you to be able to resolve your issues from the very comfort of
          your home - all you need is an internet connection and a smartphone or
          computer!
        </p>
      </div>

      <div className="points__container">
        {data.map(singleData => {
          return (
            <div
              key={singleData.title}
              className="card--icon-card"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <img src={singleData.icon} alt="quick icons" />
              <div className="u-margin-top-small"></div>
              <p>{singleData.title}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
